import { FC } from 'react'
import Link from 'next/link'
import { Logo } from '@/components/atoms/Logo'
import ProjectSvg from '@/public/images/common/projects.svg'
import ProjectActiveSvg from '@/public/images/common/projects_active.svg'
import HomeSvg from '@/public/images/common/home.svg'
import HomeActiveSvg from '@/public/images/common/home_active.svg'
import ExpertSvg from '@/public/images/common/experts.svg'
import ExpertActiveSvg from '@/public/images/common/experts_active.svg'
import ProfileSvg from '@/public/images/common/profile.svg'
import ProfileActiveSvg from '@/public/images/common/profile_active.svg'
import MessageSvg from '@/public/images/common/message.svg'
import MessageActiveSvg from '@/public/images/common/message_active.svg'
import PostSvg from '@/public/images/common/edit_note_icon.svg'
import NftsSvg from '@/public/images/common/nfts_icon.svg'
import NftsActiveSvg from '@/public/images/common/nfts_icon_active.svg'
import AdSvg from '@/public/images/common/ad_icon.svg'
import AdActiveSvg from '@/public/images/common/ad_icon_active.svg'
import { VerticalNavigationMenuItem } from '@/components/molecules/VerticalNavigationMenuItem'
import { getIsActive, getSignInPath, useStores } from '@/utils'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type MenuItem = {
  name: string
  link?: string
  isActive?: boolean
  icon?: React.SVGProps<SVGElement>
  iconActive?: React.SVGProps<SVGElement>
  isExternal?: boolean
}

export const VerticalNavigationMenuGroup: FC = observer(() => {
  const { ui, viewer } = useStores()
  const router = useRouter()

  const toggleForumModal = () => {
    ui.toggleForumModal()
  }

  const privateItems: MenuItem[] = [
    {
      name: 'Home',
      link: '/',
      isActive: getIsActive(['/'], router, true, true),
      icon: <HomeSvg />,
      iconActive: <HomeActiveSvg />,
    },
    {
      name: 'Experts',
      link: '/experts',
      isActive: getIsActive(['/experts'], router, true, true),
      icon: <ExpertSvg />,
      iconActive: <ExpertActiveSvg />,
    },
    {
      name: 'Projects',
      link: '/jobs',
      isActive: getIsActive(['/jobs'], router, false, true),
      icon: <ProjectSvg />,
      iconActive: <ProjectActiveSvg />,
    },
    {
      name: 'Notifications',
      isActive: getIsActive(['/account/notifications'], router, true, true),
    },
    {
      name: 'Message',
      link: '/messages',
      isActive: getIsActive(['/messages'], router, false, true),
      icon: <MessageSvg />,
      iconActive: <MessageActiveSvg />,
    },
    {
      name: 'Invite',
    },
    {
      name: 'Wallet',
    },
    {
      name: 'Profile',
      link: `/users/${viewer.viewer?.username}`,
      isActive: getIsActive(['/users/[id]'], router, false, true),
      icon: <ProfileSvg />,
      iconActive: <ProfileActiveSvg />,
    },
    {
      name: 'Buy NFT',
      link: '/nfts',
      isActive: getIsActive(['/nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'Citizenship NFT',
      link: '/citizenship-nfts',
      isActive: getIsActive(['/citizenship-nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'AQA Ads',
      link: '/aqa-ads',
      isActive: getIsActive(['/aqa-ads'], router, true, true),
      icon: <AdSvg />,
      iconActive: <AdActiveSvg />,
    },
    {
      name: 'Log out',
    },
  ]
  const publicItems: MenuItem[] = [
    {
      name: 'Home',
      link: '/',
      isActive: getIsActive(['/'], router, true, true),
      icon: <HomeSvg />,
      iconActive: <HomeActiveSvg />,
    },
    {
      name: 'Experts',
      link: '/experts',
      isActive: getIsActive(['/experts'], router, true, true),
      icon: <ExpertSvg />,
      iconActive: <ExpertActiveSvg />,
    },
    {
      name: 'Projects',
      link: '/jobs',
      isActive: getIsActive(['/jobs'], router, false, true),
      icon: <ProjectSvg />,
      iconActive: <ProjectActiveSvg />,
    },
    {
      name: 'Citizenship NFT',
      link: '/citizenship-nfts',
      isActive: getIsActive(['/citizenship-nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'AQA Ads',
      link: '/aqa-ads',
      isActive: getIsActive(['/aqa-ads'], router, true, true),
      icon: <AdSvg />,
      iconActive: <AdActiveSvg />,
    },
  ]

  if (viewer.isSignedIn) {
    return (
      <div className={styles.container}>
        <div className={styles.logoContent}>
          <Link href='/'>
            <a className={styles.logo}>
              <Logo />
            </a>
          </Link>
        </div>
        <div className={styles.itemList}>
          {privateItems.map((item) => (
            <VerticalNavigationMenuItem
              key={item.name}
              name={item.name}
              link={item.link}
              isActive={item.isActive}
              icon={item.icon}
              iconActive={item.iconActive}
              isExternal={item.isExternal}
            />
          ))}
        </div>
        <button type='button' onClick={toggleForumModal} className={styles.btn}>
          <div className={styles.postText}>Post</div>
          <div className={styles.postIcon}>
            <PostSvg />
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.logoContent}>
        <Link href='/'>
          <a className={styles.logo}>
            <Logo />
          </a>
        </Link>
      </div>
      <div className={styles.itemList}>
        {publicItems.map((item) => (
          <VerticalNavigationMenuItem
            key={item.name}
            name={item.name}
            link={item.link}
            isActive={item.isActive}
            icon={item.icon}
            iconActive={item.iconActive}
            isExternal={item.isExternal}
          />
        ))}
      </div>
      <button
        type='button'
        className={styles.signInBtn}
        onClick={() => {
          const path = getSignInPath(router.asPath)
          router.push(path)
        }}
      >
        <div className={styles.signInText}>Log in</div>
      </button>
      <button
        type='button'
        className={styles.btn}
        onClick={() => {
          router.push('/signup')
        }}
      >
        <div className={styles.text}>Join</div>
      </button>
    </div>
  )
})
