import { FC } from 'react'
import ProjectSvg from '@/public/images/common/projects.svg'
import ProjectActiveSvg from '@/public/images/common/projects_active.svg'
import ProfileSvg from '@/public/images/common/profile.svg'
import ProfileActiveSvg from '@/public/images/common/profile_active.svg'
import MessageSvg from '@/public/images/common/message.svg'
import MessageActiveSvg from '@/public/images/common/message_active.svg'
import ExpertSvg from '@/public/images/common/experts.svg'
import ExpertActiveSvg from '@/public/images/common/experts_active.svg'
import NftsSvg from '@/public/images/common/nfts_icon.svg'
import NftsActiveSvg from '@/public/images/common/nfts_icon_active.svg'
import AdSvg from '@/public/images/common/ad_icon.svg'
import AdActiveSvg from '@/public/images/common/ad_icon_active.svg'
import { MobileNavigationHeaderMenuItem } from '@/components/molecules/MobileNavigationHeaderMenuItem'
import { getIsActive } from '@/utils'
import { useRouter } from 'next/router'
import { IPublicUser } from '@/types'
import styles from './index.module.scss'

type MenuItem = {
  name: string
  link?: string
  isActive?: boolean
  icon?: React.SVGProps<SVGElement>
  iconActive?: React.SVGProps<SVGElement>
  isExternal?: boolean
}

type Props = {
  user: IPublicUser
}

export const MobileNavigationHeaderMenuList: FC<Props> = ({ user }) => {
  const router = useRouter()

  const privateItems: MenuItem[] = [
    {
      name: 'Profile',
      link: `/users/${user?.username}`,
      isActive: getIsActive(['/users/[id]'], router, false, true),
      icon: <ProfileSvg />,
      iconActive: <ProfileActiveSvg />,
    },
    {
      name: 'Invite',
    },
    {
      name: 'Notifications',
      isActive: getIsActive(['/account/notifications'], router, true, true),
    },
    {
      name: 'Messages',
      link: '/messages',
      isActive: getIsActive(['/messages'], router, false, true),
      icon: <MessageSvg />,
      iconActive: <MessageActiveSvg />,
    },
    {
      name: 'Experts',
      link: '/experts',
      isActive: getIsActive(['/experts'], router, true, true),
      icon: <ExpertSvg />,
      iconActive: <ExpertActiveSvg />,
    },
    {
      name: 'Projects',
      link: '/jobs',
      isActive: getIsActive(['/jobs'], router, false, true),
      icon: <ProjectSvg />,
      iconActive: <ProjectActiveSvg />,
    },
    {
      name: 'Buy NFT',
      link: '/nfts',
      isActive: getIsActive(['/nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'Citizenship NFT',
      link: '/citizenship-nfts',
      isActive: getIsActive(['/citizenship-nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'AQA Ads',
      link: '/aqa-ads',
      isActive: getIsActive(['/aqa-ads'], router, true, true),
      icon: <AdSvg />,
      iconActive: <AdActiveSvg />,
    },
    {
      name: 'Log out',
    },
  ]

  return (
    <>
      <div className={styles.container}>
        {privateItems.map((item) => (
          <MobileNavigationHeaderMenuItem
            key={item.name}
            name={item.name}
            link={item.link}
            isActive={item.isActive}
            icon={item.icon}
            iconActive={item.iconActive}
            isExternal={item.isExternal}
          />
        ))}
      </div>
    </>
  )
}
