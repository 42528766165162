import { FC } from 'react'
import HomeSvg from '@/public/images/common/home.svg'
import HomeActiveSvg from '@/public/images/common/home_active.svg'
import ExpertsSvg from '@/public/images/common/experts.svg'
import ExpertActiveSvg from '@/public/images/common/experts_active.svg'
import ProjectSvg from '@/public/images/common/projects.svg'
import ProjectActiveSvg from '@/public/images/common/projects_active.svg'
import AdSvg from '@/public/images/common/ad_icon.svg'
import AdActiveSvg from '@/public/images/common/ad_icon_active.svg'
import { MobileNavigationHeaderMenuItem } from '@/components/molecules/MobileNavigationHeaderMenuItem'
import { getIsActive } from '@/utils'
import { useRouter } from 'next/router'
import { SignInAndJoinBtnGroup } from '@/components/molecules/SignInAndJoinBtnGroup'
import NftsSvg from '@/public/images/common/nfts_icon.svg'
import NftsActiveSvg from '@/public/images/common/nfts_icon_active.svg'
import styles from './index.module.scss'

type MenuItem = {
  name: string
  link?: string
  isActive?: boolean
  icon?: React.SVGProps<SVGElement>
  iconActive?: React.SVGProps<SVGElement>
  isExternal?: boolean
}

export const NotSignedInMobileHeaderRightDropdownMenuList: FC = () => {
  const router = useRouter()
  const items: MenuItem[] = [
    {
      name: 'Home',
      link: '/',
      isActive: getIsActive(['/'], router, true, true),
      icon: <HomeSvg />,
      iconActive: <HomeActiveSvg />,
    },
    {
      name: 'Experts',
      link: '/experts',
      isActive: getIsActive(['/experts'], router, true, true),
      icon: <ExpertsSvg />,
      iconActive: <ExpertActiveSvg />,
    },
    {
      name: 'Projects',
      link: '/jobs',
      isActive: getIsActive(['/jobs'], router, false, true),
      icon: <ProjectSvg />,
      iconActive: <ProjectActiveSvg />,
    },
    {
      name: 'Citizenship NFT',
      link: '/citizenship-nfts',
      isActive: getIsActive(['/citizenship-nfts'], router, true, true),
      icon: <NftsSvg />,
      iconActive: <NftsActiveSvg />,
    },
    {
      name: 'AQA Ads',
      link: '/aqa-ads',
      isActive: getIsActive(['/aqa-ads'], router, true, true),
      icon: <AdSvg />,
      iconActive: <AdActiveSvg />,
    },
  ]

  return (
    <div className={styles.container}>
      <SignInAndJoinBtnGroup />
      <div className={styles.list}>
        {items.map((item) => (
          <MobileNavigationHeaderMenuItem
            key={item.name}
            name={item.name}
            link={item.link}
            isActive={item.isActive}
            icon={item.icon}
            iconActive={item.iconActive}
            isExternal={item.isExternal}
          />
        ))}
      </div>
    </div>
  )
}
